
import request from '@/utils/request';


export function getReportfUrl(){
    
      return request({
          url: '/wxmain/getReportfUrl',
          method: 'post',
          params:{}
        })
  
  }

  export function getTJReportfUrl(){
      return request({
          url: '/wxmain/getTjReportfUrl',
          method: 'post',
          params:{}
        })
  }