<template>
  <iframe :src="'pdf/viewer.html?file='+pdfurl" width="100%" :height="clientHeight"  >
  </iframe>
</template>
<script>
export default {
  name: 'PdfView',
  components: {
     
  },
  props:{
     pdfurl:{
     type: String,
     required:true
    },
  }
  ,data(){
    return{
        clientHeight:'',    
    }
  },mounted(){
      this.clientHeight =   `${document.documentElement.clientHeight}`          //document.body.clientWidth;
      window.onresize = function temp() {
        this.clientHeight = `${document.documentElement.clientHeight}`;
      };
  }
}
</script>
<style scoped>
</style>
